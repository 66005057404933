import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import FooterLinks from "./FooterLinks"
import PageLink from "../PageLink"
import Chevron from "../Symbols/chevron"
import Tweets from "../Tweets"

import { createLocalLink } from "../../utils/createLocalLink"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FOOTER_DETAILS {
      allWp {
        edges {
          node {
            options {
              options {
                emailAddress
                telephone
              }
            }
          }
        }
      }
    }
  `)

  const footerDetails = data.allWp.edges[0].node.options.options
  return (
    <footer className="site-footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-bp1-12">
            <h3 className="site-footer__title">
              <PageLink
                url={createLocalLink(`/contact`)}
                colour="orange"
                cursor="alt"
              >
                Let's Talk
              </PageLink>
            </h3>
            <ul className="site-footer__details">
              <li className="site-footer__detail">
                <a href={`tel:${footerDetails.telephone}`}>
                  {footerDetails.telephone}
                </a>
              </li>
              <li className="site-footer__detail">
                <a href={`mailto:${footerDetails.emailAddress}`}>
                  {footerDetails.emailAddress}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-bp1-12 col-bp4-6"></div>
        </div>
        <div className="row between-bp1 row--sub-footer">
          <div className="col-bp1-12 col-bp5-4 col-bp6-5">
            <p className="copyright">
              &copy; 2003 - {new Date().getFullYear()} Broadgate Creative Ltd
            </p>
          </div>
          <div className="col-bp1-12 col-bp5-8 col-bp6-7">
            <FooterLinks />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
