import React, { Component } from 'react';
import { connect } from 'react-redux'
import lottie from 'lottie-web';
import animationData from "../Symbols/broadgate-logo.json"

class Lottie extends Component {
  componentDidMount() {
    // Opts For logo symbol  
    const lottieOptions = {
      container: this.el,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      segments: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid meet",
      },
      eventListeners: [],
      isStopped: false,
      isPaused: false,
      speed: 1,
      ariaRole: 'button',
      ariaLabel: 'Broadgate Logo',
      isClickToPauseDisabled: false,
      title: 'Broadgate',    
    };

    // Setup our lottie anim with options
    this.anim = lottie.loadAnimation(lottieOptions);

    // Go to last frame on load as we want to start with complete logo shown
    if(this.props.logoHasSymbol) {
      this.anim.goToAndStop(this.anim.firstFrame + this.anim.totalFrames-1, true)
    }
  }
  
  componentDidUpdate() {
    // Animate the logo in either dir
    // Based on whether sticky header is activated
    if(!this.props.logoHasSymbol) { 
      // Remove symbol by going to first (empty) frame
      this.anim.goToAndStop(this.anim.firstFrame, true)
    } else {
      // Play Lottie anim
      this.anim.setDirection(1)
      this.anim.setSpeed(1.25)
      setTimeout(() => {
        this.anim.play()  
      }, 575)
    } 
  }
  
  render() {
    return (
      <div
        ref={(c) => {
          this.el = c;
        }}
      />
    );
  }
}

export default connect(state => ({
  logoHasSymbol: state.logoHasSymbol.logoHasSymbol
}), null)(Lottie)
