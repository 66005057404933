import React from "react"
import { StaticQuery, graphql } from "gatsby"

import PageLink from "../PageLink"
import { createLocalLink } from "../../utils/createLocalLink"

const MENU_QUERY = graphql`
  query GET_MENU_ITEMS {
    allWpMenuItem(filter: {locations: {eq: PRIMARY_MENU}}) {
      edges {
        node {
          id
          label
          url
          menuModifier {
            menuitemcolour
          }
        }       
      }
    }
  }
`

const renderMenuItem = (menuItem, { location, dispatch, stickyHeader }) => {
  const link = createLocalLink(menuItem.url)
  const colour = menuItem.menuModifier.menuitemcolour
  const splitUrl =
    location && location.pathname && location.pathname !== "/"
      ? location.pathname.split("/")
      : false

  let current
  if((splitUrl && location.pathname === link) || `/${splitUrl[1]}/` === link) {
   current = `current`
  } 
  
  return (
    <li key={menuItem.id} role="none">
      {link ? (
        <PageLink
          menuLink={true}
          url={createLocalLink(menuItem.url)}
          dispatch={dispatch}
          colour={colour}
          className={current}
          stickyHeader={stickyHeader}
          menuLink={true}
        >
          <span className={colour}>{menuItem.label}</span>
        </PageLink>
      ) : (
        menuItem.label
      )}
    </li>
  )
}

const SiteMenu = ({ location, dispatch, stickyHeader }) => (
  <StaticQuery
    query={MENU_QUERY}
    render={data => {
      return <ul className="site-nav__items" role="menu">
        {data.allWpMenuItem.edges ? data.allWpMenuItem.edges.map(menuItem => {
          return renderMenuItem(menuItem.node, { location, dispatch, stickyHeader })
        }) : null}
      </ul>
    }}
  />
)

export default SiteMenu
