import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { gsap } from "gsap"
import { useIsJsEnabled } from "gatsby-plugin-js-fallback"

import Lottie from "./Lottie"
import StaticSiteLogo from "./StaticSiteLogo"
import PageLink from "../PageLink"

import SiteMenu from "../SiteMenu"
import SiteMenuTrigger from "../SiteMenu/SiteMenuTrigger"
import SocialLinks from "./SocialLinks"
import Telephone from "./Telephone"
import Breadcrumbs from "../Breadcrumbs"

const SiteHeader = props => {
  const [reveal, setReveal] = useState(false)
  const headerEl = useRef(null)
  const logoText = useRef(null)
  const {
    location,
    dispatch,
    siteLoaded,
    navActive,
    siteHeaderActive,
    logoHasSymbol,
    stickyHeader,
  } = props
  const isJsEnabled = useIsJsEnabled()

  useEffect(() => {
    // logo redraws itself and we reset HasSymbol to true
    if (logoHasSymbol) {
      let logoX = 0
      if (typeof window !== "undefined") {
        logoX = window.innerWidth > 900 ? 67 : 57
        gsap.to(logoText.current, { x: logoX, duration: 1, delay: 0.5 }) // move logo text as symbol animates in
      }
    } else {
      // bring in logo
      gsap.to(logoText.current, { x: 0, duration: 0 })
    }
  }, [logoHasSymbol])

  return (
    <>
      <header
        ref={headerEl}
        className={`${navActive ? `site-header active` : `site-header`} ${
          siteLoaded ? `reveal` : ``
        }`}
        style={{
          opacity: siteLoaded && siteHeaderActive ? 1 : 0,
        }}
      >
        <PageLink
          className="site-logo"
          url={`/`}
          dispatch={dispatch}
          colour={`black`}
          menuLink={false}
        >
          {isJsEnabled ? <Lottie /> : <StaticSiteLogo />}
          <span ref={logoText}>Broadgate</span>
        </PageLink>
        <div className="site-header__tools">
          <Breadcrumbs />
          <SiteMenuTrigger />
        </div>
      </header>

      <nav
        id="site-nav"
        className={`site-nav ${navActive ? "active" : ""}`}
        style={{
          transform: navActive ? `translateY(0%)` : `translateY(-110%)`,
        }}
        aria-labelledby="site-nav-trigger"
        aria-expanded="false"
      >
        <div className="nav__inner">
          <SiteMenu
            location={location}
            navActive={navActive}
            dispatch={dispatch}
            stickyHeader={stickyHeader}
          />
        </div>

        <div
          className="nav__footer"
          style={{
            opacity: navActive ? 1 : 0,
            transitionDelay: navActive ? `1.6s` : `0s`,
          }}
        >
          <SocialLinks />
          <Telephone />
        </div>
      </nav>
    </>
  )
}

export default connect(
  state => ({
    siteHeaderActive: state.siteHeaderActive.siteHeaderActive,
    navActive: state.navActive.navActive,
    logoHasSymbol: state.logoHasSymbol.logoHasSymbol,
    stickyHeader: state.stickyHeader.stickyHeader,
    siteLoaded: state.siteLoaded.siteLoaded,
  }),
  null
)(SiteHeader)
