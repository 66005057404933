import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { toggleNavActive } from "../../state/navActive"
import { gsap } from "gsap"

const SiteMenuTrigger = props => {
  const {
    dispatch,
    navActive,
    stickyHeader
  } = props

  // Menu Toggle
  const handleNav = () => {
    // Dispatch action to Redux Reducer to toggle Site Nav Drawer
    dispatch(toggleNavActive(!navActive))
  }

  // Nav classes
  const navTriggerClasses = `site-nav-trigger ${navActive ? "active" : ""}`
 
  return (
    <button 
      id={stickyHeader ? `sticky-site-nav-trigger` : `site-nav-trigger`}
      className={navTriggerClasses} 
      onClick={handleNav}
      aria-controls="#site-nav"
      aria-expanded={navActive ? `true` : `false`}
      tabIndex={stickyHeader ? `-1` : null}
    >
      {navActive ? "Close" : "Menu"}
    </button>
  )
}

export default connect(
  state => ({
    navActive: state.navActive.navActive
  }),
  null
)(SiteMenuTrigger)
