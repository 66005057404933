import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const SocialLinks = () => {
  const data = useStaticQuery(graphql`
    query SOCIAL_LINKS {
      allWp {
        edges {
          node {
            options {
              options {
                socialLinks {
                  socialNetwork
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  
  const socialItems = data.allWp.edges[0].node.options.options.socialLinks 
  return <ul className="socials">
    {socialItems.map((item,i) => {
      return <li key={i}><a href={item.url}>{item.socialNetwork}</a></li>
    })}
  </ul>
}

export default SocialLinks