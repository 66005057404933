import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Telephone = () => {
  const data = useStaticQuery(graphql`
    query TELEPHONE {
      allWp {
        edges {
          node {
            options {
              options {
                telephone
              }
            }
          }
        }
      }
    }
  `)
  
  const telephone = data.allWp.edges[0].node.options.options.telephone
  return <a href={`tel:${telephone}`} className="site-header__telephone">{telephone}</a>
}

export default Telephone