import { toggleNavActive } from "../state/navActive"
import { toggleOverlayActive } from "../state/overlay"
import { toggleBackground } from "../state/background"
import { toggleBreadcrumbsShow } from "../state/breadcrumbs"
import { toggleSiteHeader, toggleStickyHeader, toggleStickyHeaderColour } from "../state/header"
import { toggleLogoHasSymbol } from "../state/logo"

export const handlePageEntry = (dispatch, colour, stickyHeader, childLink) => {
  let initEntry = new Promise(resolve => {
    resolve(true)
  })

  initEntry.then(() => {
    if(!childLink) {
      // We change the bg colour of body before new page comes in
      // when overlay is hiding page
      dispatch(toggleBackground(colour)) 
    }
  })
  .then(() => {    
    // Async Close Nav drawer
    dispatch(toggleNavActive(false))    

    if (stickyHeader) {
      // If we are down the page and sticky header is there
      // Let's slide it out of viewport        
      dispatch(toggleStickyHeader(false))
    }
  })
  .then(() => {
    // Time the bg colour change of the sticky header
    // so that it's hidden from view
    setTimeout(() => {
      dispatch(toggleOverlayActive(false)) // Slide out the site overlay
      dispatch(toggleStickyHeaderColour(colour))
      dispatch(toggleSiteHeader(true))
    }, 750)
  })
  .then(() => {
    setTimeout(() => {
      // turn on logo symbol
      dispatch(toggleLogoHasSymbol(true))
      // Turn on our Breadcrumbs after a delay
      dispatch(toggleBreadcrumbsShow(true))
    }, 1400)
  })
}