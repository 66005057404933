import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PageLink from "../PageLink"
import { createLocalLink } from "../../utils/createLocalLink"

const FooterLinks = () => {
  const data = useStaticQuery(graphql`
    query FOOTER_LINKS {
      allWp {
        edges {
          node {
            options {
              options {
                socialLinks {
                  socialNetwork
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const socialItems = data.allWp.edges[0].node.options.options.socialLinks
  return (
    <ul className="site-footer__links">
      <li>
        <PageLink
          url={createLocalLink(`/contact`)}
          colour="orange"
          menuLink={false}
        >
          Contact
        </PageLink>
      </li>
      <li>
        <PageLink
          url={createLocalLink(`/privacy`)}
          colour="black"
          menuLink={false}
        >
          Privacy
        </PageLink>
      </li>
      {socialItems.map((item, i) => {
        return (
          <li key={i}>
            <a href={item.url} rel="noopener">
              {item.socialNetwork}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default FooterLinks
