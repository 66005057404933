import React from "react"
import { connect } from "react-redux"
import PageLink from "../PageLink"

const Breadcrumbs = props => {
  const current = props.breadcrumbs ? props.breadcrumbs.current : null
  const currentTitle = current ? current.title : null
  const parent = props.breadcrumbs ? props.breadcrumbs.parent : null
  const parentTitle = parent ? parent.title : null
  return (
    <nav
      className={`breadcrumbs breadcrumbs--${
        current && current.path ? current.path : ""
      }`}
      style={{ opacity: props.breadcrumbsShow ? 1 : 0 }}
    >
      <ul>
        <li className="breadcrumb">
          <PageLink
            url="/"
            title="Home"
            dispatch={props.dispatch}
            colour="black"
            menuLink={false}
          >
            <span>Home</span>
          </PageLink>
        </li>
        {props.breadcrumbs.parent ? (
          <li className="breadcrumb">
            <PageLink
              url={parent.path}
              dispatch={props.dispatch}
              colour={props.background}
              title={parent.title}
              childLink={true}
              menuLink={false}
            >
              <span>{parentTitle}</span>
            </PageLink>
          </li>
        ) : null}
        <li className="breadcrumb current">
          <a href={`#`} title={currentTitle}>
            <span>{currentTitle}</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default connect(
  state => ({
    breadcrumbs: state.breadcrumbs.breadcrumbs,
    breadcrumbsShow: state.breadcrumbsShow.breadcrumbsShow,
    background: state.background.background,
  }),
  null
)(Breadcrumbs)
