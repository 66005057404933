import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const Seo = ({ seoTitle, seoDesc, ogTitle, ogDesc, ogImage }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const {
          site,
          allWp,     
        } = data
        const metaDesc = seoDesc ? seoDesc : site.siteMetadata.description
        const defaultImage = allWp.nodes[0].seo.openGraph.defaultImage.localFile ? allWp.nodes[0].seo.openGraph.defaultImage.localFile.publicURL : null
        return (
          <Helmet
            htmlAttributes={{
              lang: `en`
            }}
            title={seoTitle ? seoTitle : site.siteMetadata.title}
            titleTemplate={seoTitle ? seoTitle : `%s | ${site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDesc,
              },
              {
                property: `image`,
                content: ogImage ? ogImage : defaultImage,
              },              
              {
                property: `og:title`,
                content: ogTitle ? ogTitle : site.siteMetadata.title,
              },
              {
                property: `og:description`,
                content: ogDesc ? ogDesc : metaDesc,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: ogImage ? ogImage : defaultImage,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: `Broadgate`,
              },
              {
                name: `twitter:title`,
                content: seoTitle ? seoTitle : site.siteMetadata.title,
              },
              {
                name: `twitter:description`,
                content: ogDesc ? ogDesc : metaDesc,
              },
            ]}
          />
        )
      }}
    />
  )
}

export default Seo

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allWp {
      nodes {
        seo {
          openGraph {
            defaultImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`
