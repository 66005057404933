import { gsap } from "gsap"

import { toggleOverlayColour, toggleOverlayActive } from "../state/overlay"
import { toggleCursorStyle } from "../state/cursor"
import { toggleBreadcrumbsShow } from "../state/breadcrumbs"
import { toggleSiteHeader, toggleStickyHeader } from "../state/header"

// Simple Exit
export const handlePageExit = (dispatch, stickyHeader) => {
  // Navigation is hiding content,
  // so when we exit we just need to 
  // slide out sticky header if it's there,
  // hide breadcrumbs and cursor animation
 
  // Hide breadcrumbs
  dispatch(toggleBreadcrumbsShow(false))

  // Turn off the site header, so that we can fade it 
  // back in on entry
  if (stickyHeader) {
    dispatch(toggleSiteHeader(false))
  }
}

// More complex exit for internal page links (not main nav items)
export const handleInternalPageExit = (dispatch, stickyHeader, colour, childLink) => {
  // Setup our promise so that we can handle timing transitions (via state)
  let initExit = new Promise(resolve => {
    resolve(true)
  })

  // Begin chaining with promises
  initExit.then(() => {
    if (childLink) {
      // If going to a child page 
      // as we have the same bg colour as current page
      // we just want to fade out current content on exit
      fadeContentOut()     
    }

    if(stickyHeader){
      // If we are down the page and sticky header is active
      // Let's slide it out of viewport
      dispatch(toggleStickyHeader(false))
      
      // Turn off the site header, so that we can fade it 
      // back in on entry
      dispatch(toggleSiteHeader(false))
    }

    // Overlay colour change to new page colour
    dispatch(toggleOverlayColour(colour))
      
    // Hide cursor animation on exit
    dispatch(toggleCursorStyle(false))
    
    // Hide breadcrumbs
    dispatch(toggleBreadcrumbsShow(false))
  })
  .then(() => {
    if(!childLink){
      // Then bring in the overlay to hide the
      // new content as it comes in
      dispatch(toggleOverlayActive(true))
    }
  })

}

const fadeContentOut = () => {
  // Fade out page elements (this opacity is reversed on page entry)
  gsap.to(".main-content, .section--hero, .intro-title, .site-footer", {
    opacity: 0,
    ease: `power2.out`,
    duration: .5
  })
}
