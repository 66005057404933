import React from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"

import { handlePageEntry } from "../../utils/handlePageEntry"
import { handlePageExit, handleInternalPageExit } from "../../utils/handlePageExit"
import { toggleNavActive } from "../../state/navActive"

const prefersReducedMotion = (typeof window !== "undefined") ? window.matchMedia("(prefers-reduced-motion: reduce)") : null

const PageLink = props => props.menuLink ? <MenuLink props={props} /> : <InternalLink props={props} />

const MenuLink = props => {
  const {
    url,
    title,
    className,
    dispatch,
    colour,
    children,
    stickyHeader,
  } = props.props

  // Current item => if we're on the page as link, just close nav
  if(className === 'current') {
    return <a 
      href={url} 
      className={className} 
      title={title}
      onClick={(e) => {
        e.preventDefault()
        dispatch(toggleNavActive(false))
      }}>
        {children}
      </a>
  }

  if (prefersReducedMotion != null && prefersReducedMotion.matches) {
    // Menu Links => Navigate to the page with transitions
    return (
      <a
        href={url}
        className={className}
        title={title}
        role="menuitem"
        onClick={(e) => {
          e.preventDefault()

          // Change the page with transitions
          let promise = new Promise(resolve => {
            resolve(true)
          })
          promise
            .then(() => {
              handlePageExit(dispatch, stickyHeader)
            })
            .then(() => {
              setTimeout(() => navigate(url), 250)
            })
            .then(() => {
              setTimeout(() => handlePageEntry(dispatch, colour, stickyHeader), 750)
            })
        }}
      >
        {children}
      </a>
    )
  }

  return (
    <TransitionLink
      to={url}
      className={className}
      title={title}
      exit={{
        length: 0.5,
        trigger: () => handlePageExit(dispatch, stickyHeader)
      }}
      entry={{
        trigger: () => handlePageEntry(dispatch, colour, stickyHeader)  
      }}
      role="menuitem"
    >
      {children}
    </TransitionLink>
  )
}

const InternalLink = props => {
  const {
    url,
    title,
    className,
    dispatch,
    colour,
    children,
    stickyHeader,
    childLink,
    cursor,
    tabIndex
  } = props.props
  
  // Handle internal links
  if (prefersReducedMotion != null && prefersReducedMotion.matches) {
    return (
      <a
        href={url}
        data-cursor={cursor === "alt" ? `alt` : null}
        className={className}
        title={title}
        tabIndex={tabIndex}
        onClick={(e) => {
          e.preventDefault()

          // Change the page with transitions
          let promise = new Promise(resolve => {
            resolve(true)
          })
          promise
            .then(() => {
              handleInternalPageExit(dispatch, stickyHeader, colour, childLink)
            })
            .then(() => {
              setTimeout(() => navigate(url), 1500)
            })
            .then(() => {
              setTimeout(() => handlePageEntry(dispatch, colour, stickyHeader, childLink), 1000)
            })
        }}
      >
        {children}
      </a>
    )
  }
  return (
    <TransitionLink
        to={url}
        data-cursor={cursor === "alt" ? `alt` : null}
        className={className}
        title={title}
        tabIndex={tabIndex}
        exit={{
          length: 2,
          trigger: () => handleInternalPageExit(dispatch, stickyHeader, colour, childLink)
        }}
        entry={{
          length: 1,
          delay: 1,
          trigger: () => handlePageEntry(dispatch, colour, stickyHeader, childLink)  
        }}        
    >
      {children}
    </TransitionLink>
  )  
}

export default connect(
  state => ({
    navActive: state.navActive.navActive,
    stickyHeader: state.stickyHeader.stickyHeader,
  }),
  null
)(PageLink)
